import styled from 'styled-components/macro'
import { Button, Typography, Card, Paper } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import shadows from '@mui/material/styles/shadows'

export const dataTableTheme = (appTheme) =>
  createTheme({
    components: {
      MuiPaper: {
        variants: [
          {
            props: {
              variant: 'elevation',
            },
            style: {
              boxShadow: shadows[3],
            },
          },
        ],
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            width: 300,
            boxShadow: shadows[3],
            backgroundColor: appTheme.palette.background.paper,
          },
          title: {
            lineHeight: 3,
          },
          header: {
            marginBottom: 0,
          },
          gridListTile: {
            styleOverrides: {
              paddingLeft: '25px',
              marginLeft: '25px',
            },
            sx: {
              pl: 28,
            },
          },

          reset: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingRight: 4,
          },
          resetLink: {
            button: {
              variants: [
                {
                  props: {
                    variant: 'outlined',
                  },
                },
              ],
            },
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            paddingLeft: '8px',
            '&[data-colindex="5"], &[data-colindex="8"]': {
              '& > *': {
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
              },
              '& .MuiButton-root': {
                margin: 'auto',
              },
              '&[data-colindex="4"], &[data-colindex="1"], &[data-colindex="2"], &[data-colindex="3"], &[data-colindex="4", &[data-colindex="6"]':
                {
                  '& > *': {
                    display: 'flex',
                    justifyContent: 'left',
                    textAlign: 'left',
                  },
                  '& .MuiButton-root': {
                    margin: 'auto',
                    paddingLeft: '8px',
                  },
                },
              '&[data-colindex="7"]': {
                '& > *': {
                  display: 'flex',
                  justifyContent: 'right',
                  textAlign: 'right',
                },
                '& .MuiButton-root': {
                  margin: 'auto',
                },
              },
            },
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'filled',
          margin: 'dense',
          size: 'medium',
          fullWidth: false,
          sx: {
            ml: 2,
            width: '230px',
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          margin: 'dense',
        },
      },
    },
  })

export const CustomizedButton = styled(Button)`
  && {
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    right: 60px;
    border: 1px solid ${(props) => props.theme.tokens.primary};
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.tokens.primary};
    /* Styles for the icon */
    & > .MuiSvgIcon-root {
      color: ${(props) => props.theme.tokens.primary};
      margin-right: 8px;
    }
  }
`
export const OuterSurface = styled(Card)`
  && {
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2%;
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 2000px) {
      width: 70vw;
      margin: 60px auto;
    }
    @media (min-width: 2200px) {
      width: 60vw;
    }
    width: 75vw;
  }
`
export const HeaderTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.onSurfaceVariant};
    margin-right: 60px;
  }
`

export const TableContainer = styled(Paper)`
  && {
    @media (min-width: 2000px) {
      width: 70vw;
    }
    @media (min-width: 2200px) {
      width: 60vw;
    }
    width: 75vw;
  }
`
