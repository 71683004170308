import * as React from 'react'
import { lazy, Suspense } from 'react'
import styled from 'styled-components/macro'
import {
  Tooltip,
  IconButton as MuiIconButton,
  CircularProgress,
  Typography,
} from '@mui/material'
import { useUser } from '../../services/contexts/UserContext'
import { userService } from '../../services/utils/axios'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import { getErrorResponse } from '../../services/utils/helpers'
const ErrorFeedback = lazy(() => import('../Feedback'))
const IconButton = styled(MuiIconButton)`
  svg {
    width: 20px;
    height: 20px;
    color: white;
  }
`
const HeaderUserName = styled(Typography)`
  color: white;
`
//this div is used to hold user name and logout icon and text
const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 228px;
  height: 40px;
  right: 50px;
  top: 30px;
`
const TextLogout = styled(Typography)`
  color: white;
  font-weight: bold;
`

function NavbarUserDropdown() {
  const [anchorMenu] = React.useState(null)
  const { user } = useUser()
  const [loggingOut, setLoggingOut] = React.useState(false)

  const [hasError, setHasError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState({})

  const navigate = useNavigate()
  const logout = async () => {
    try {
      let access_token = localStorage.getItem('accessToken')
      setLoggingOut(true)
      const response = await userService.post(
        `/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      const data = response.data?.data
      setLoggingOut(false)
      if (data !== null && data !== undefined) {
        localStorage.removeItem('accessToken')
        // no error log out
        setLoggingOut(false)
        navigate('/login')
      } else {
        let errordata = getErrorResponse(response?.error?.errorCodeResponse)
        setErrorMessage(errordata)
        setHasError(true)
      }
    } catch (error) {
      if (error?.error === 'invalid_token') {
        localStorage.removeItem('accessToken')
        // ignore error when token is expired and log out
        setLoggingOut(false)
        navigate('/login')
      } else {
        let errordata = getErrorResponse(error?.error?.errorCodeResponse)
        setErrorMessage(errordata)
        setHasError(true)
      }
      setLoggingOut(false)
    }
  }

  return (
    <React.Fragment>
      {loggingOut ? (
        <div>
          <CircularProgress size={20} sx={{ color: 'action.disabled' }} />
        </div>
      ) : (
        <StyledDiv>
          <HeaderUserName variant="h5">
            {user?.operator === null ? '' : user?.operator}
          </HeaderUserName>
          <IconButton
            data-testid="logout-button"
            aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
            aria-haspopup="true"
            onClick={logout}
            color="inherit"
            size="large"
          >
            <Tooltip title="Abmelden">
              {' '}
              <LogoutIcon />
            </Tooltip>
            <TextLogout variant="h5">Logout</TextLogout>
          </IconButton>
        </StyledDiv>
      )}

      <Suspense fallback={<div />}>
        <ErrorFeedback
          open={hasError}
          severity="warning"
          title={errorMessage?.title}
          message={errorMessage?.detail}
          handleClose={() => {
            setHasError(false)
          }}
        />
      </Suspense>
    </React.Fragment>
  )
}

export default NavbarUserDropdown
