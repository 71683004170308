import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { StyledTable, CustomTablePagination } from './components'
import { UserFilterHeader } from '../UserFilterHeader'
import UserTableHead from './UserTableHead'
import UserTableBody from './UserTableBody'

export default function UserTable({ contract }) {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [searchText, setSearchText] = useState('')
  const [contractTypeFilter, setContractTypeFilter] = useState('')
  const [contractStatusFilter, setContractStatusFilter] = useState('')

  const handleSearchText = (value) => {
    setSearchText(value)
  }

  const handleContractTypeFilterChange = (value) => {
    setContractTypeFilter(value)
  }

  const handleContractStatusFilterChange = (value) => {
    setContractStatusFilter(value)
  }

  const filterContracts = (contracts) => {
    return contracts?.filter((contract) => {
      return (
        (!contractTypeFilter ||
          contract?.contractType === contractTypeFilter) &&
        (!contractStatusFilter ||
          contract?.contractStatus === contractStatusFilter)
      )
    })
  }

  const filteredData = contract.data.filter((user) => {
    const fullName = user.fullName ? user.fullName.toLowerCase() : ''
    const filteredContracts = filterContracts(user.contractDataDto || [])
    const hasFilteredContracts = filteredContracts.length > 0
    user.filteredContracts = filteredContracts
    return (
      fullName.includes(searchText.toLowerCase()) &&
      (hasFilteredContracts || (!contractTypeFilter && !contractStatusFilter))
    )
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleResetFilters = () => {
    setSearchText('')
    setContractTypeFilter('')
    setContractStatusFilter('')
  }

  return (
    <>
      <UserFilterHeader
        searchText={searchText}
        onSearchTextChanged={handleSearchText}
        contractTypeFilter={contractTypeFilter}
        onContractTypeFilterChanged={handleContractTypeFilterChange}
        contractStatusFilter={contractStatusFilter}
        onContractStatusFilterChanged={handleContractStatusFilterChange}
        onResetFiltersPressed={handleResetFilters}
      />
      <div style={{ overflowX: 'auto' }}>
        <StyledTable>
          <UserTableHead />
          <UserTableBody
            contract={contract}
            filterContracts={filterContracts}
            filteredData={filteredData}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </StyledTable>
      </div>
      <CustomTablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('LBLRowsPerPage')}
        showLastButton
        showFirstButton
      />
    </>
  )
}
